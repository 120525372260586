import { defineStore } from 'pinia'
import type { Params } from '@/shared/domain/Params'
import { useI18n } from 'vue-i18n'

import checkedIcon from '@/assets/icons/checked-icon.svg'
import pickingIcon from '@/assets/icons/picking-icon.svg'
import goingToDestinationIcon from '@/assets/icons/going-to-destination-icon.svg'
import deliveredIcon from '@/assets/icons/delivered-icon.svg'
import cancelIcon from '@/assets/icons/cancel-icon.svg'
import pickupIcon from '@/assets/icons/pickup-icon.svg'

import checkedSmallIcon from '@/assets/icons/checked-small-icon.svg'
import pickingDarkIcon from '@/assets/icons/picking-dark-icon.svg'
import goingToDestinationDarkIcon from '@/assets/icons/going-to-destination-dark-icon.svg'
import deliveredLightIcon from '@/assets/icons/delivered-light-icon.svg'
import storageDarkIcon from '@/assets/icons/storage-dark-icon.svg'
import type StepOfOrder from '../domain/StepOfOrder'
import { OrderStatus } from '../domain/OrderStatus'
import { StepStatus } from '../domain/StepStatus'

export const useStepStore = defineStore({
  id: 'step',
  state: () => ({
    steps: {
      confirmed: {
        title: '',
        help: '',
        icon: checkedIcon,
        iconActive: checkedIcon,
        iconInactive: checkedIcon
      },
      picking: {
        title: '',
        help: '',
        icon: pickingIcon,
        iconActive: pickingIcon,
        iconInactive: pickingIcon
      },
      goingToDestination: {
        title: '',
        help: '',
        icon: goingToDestinationIcon,
        iconActive: goingToDestinationIcon,
        iconInactive: goingToDestinationIcon
      },
      readyForPickup: {
        title: '',
        help: '',
        icon: pickupIcon,
        iconActive: pickupIcon,
        iconInactive: pickupIcon,
        images: [] as string[]
      },
      pickedUp: {
        title: '',
        help: '',
        icon: deliveredIcon,
        iconActive: deliveredIcon,
        iconInactive: deliveredIcon
      },
      delivered: {
        title: '',
        help: '',
        icon: deliveredIcon,
        iconActive: deliveredIcon,
        iconInactive: deliveredIcon
      },
      cancel: {
        title: '',
        help: '',
        icon: cancelIcon,
        iconActive: cancelIcon,
        iconInactive: cancelIcon
      }
    },
    currentStep: {
      position: 0,
      title: '',
      description: '',
      icon: '',
      status: StepStatus.RECEIVED
    },
    subSteps: {
      confirmed: {
        title: '',
        icon: checkedSmallIcon,
        iconActive: checkedSmallIcon,
        iconInactive: checkedSmallIcon
      },
      picking: {
        title: '',
        icon: pickingDarkIcon,
        iconActive: pickingDarkIcon,
        iconInactive: pickingDarkIcon
      },
      goingToDestination: {
        title: '',
        icon: goingToDestinationDarkIcon,
        iconActive: goingToDestinationDarkIcon,
        iconInactive: goingToDestinationDarkIcon
      },
      delivered: {
        title: '',
        icon: deliveredLightIcon,
        iconActive: deliveredLightIcon,
        iconInactive: deliveredLightIcon
      },
      readyForPickup: {
        title: '',
        icon: storageDarkIcon,
        iconActive: storageDarkIcon,
        iconInactive: storageDarkIcon
      },
      pickedUp: {
        title: '',
        icon: deliveredLightIcon,
        iconActive: deliveredLightIcon,
        iconInactive: deliveredLightIcon
      }
    },
    homeBar: {
      iconSize: '28px',
      fontSizes: {
        title: '',
        subtitle: '',
        steps: ''
      }
    }
  }),
  actions: {
    setStateFromParams(params: Params) {
      this.steps.confirmed.title =
        params.steps.confirmed.title ?? useI18n().t('confirmed')
      this.steps.confirmed.help =
        params.steps.confirmed.help ?? useI18n().t('order.hasBeenReceived')
      this.steps.confirmed.icon = params.steps.confirmed.icon ?? checkedIcon

      this.steps.picking.title =
        params.steps.picking.title ?? useI18n().t('picking')
      this.steps.picking.help =
        params.steps.picking.help ?? useI18n().t('pickerIsSelecting')
      this.steps.picking.icon = params.steps.picking.icon ?? pickingIcon
      this.steps.picking.iconActive =
        params.steps.picking.iconActive ?? pickingIcon
      this.steps.picking.iconInactive =
        params.steps.picking.iconInactive ?? pickingIcon

      this.steps.goingToDestination.title =
        params.steps.goingToDestination.title ??
        useI18n().t('goingToDestination')
      this.steps.goingToDestination.help =
        params.steps.goingToDestination.help ?? useI18n().t('driverIsOnHisWay')
      this.steps.goingToDestination.icon =
        params.steps.goingToDestination.icon ?? goingToDestinationIcon
      this.steps.goingToDestination.iconActive =
        params.steps.goingToDestination.iconActive ?? goingToDestinationIcon
      this.steps.goingToDestination.iconInactive =
        params.steps.goingToDestination.iconInactive ?? goingToDestinationIcon

      this.steps.readyForPickup.title =
        params.steps.readyToPickup.title ?? useI18n().t('pickup')
      this.steps.readyForPickup.help =
        params.steps.readyToPickup.help ?? useI18n().t('order.isReadyToPicked')
      this.steps.readyForPickup.icon =
        params.steps.readyToPickup.icon ?? pickupIcon
      this.steps.readyForPickup.iconActive =
        params.steps.readyToPickup.iconActive ?? pickupIcon
      this.steps.readyForPickup.iconInactive =
        params.steps.readyToPickup.iconInactive ?? pickupIcon
      this.steps.readyForPickup.images = params.steps.readyToPickup.images ?? []

      this.steps.pickedUp.title =
        params.steps.pickedUp.title ?? useI18n().t('pickedUp')
      this.steps.pickedUp.help =
        params.steps.pickedUp.help ?? useI18n().t('order.successPickedUp')
      this.steps.pickedUp.icon = params.steps.pickedUp.icon ?? deliveredIcon
      this.steps.pickedUp.iconActive =
        params.steps.pickedUp.iconActive ?? deliveredIcon
      this.steps.pickedUp.iconInactive =
        params.steps.pickedUp.iconInactive ?? deliveredIcon

      this.steps.delivered.title =
        params.steps.delivered.title ?? useI18n().t('delivered')
      this.steps.delivered.help =
        params.steps.delivered.help ??
        useI18n().t('order.successfullyDelivered')
      this.steps.delivered.icon = params.steps.delivered.icon ?? deliveredIcon
      this.steps.delivered.iconActive =
        params.steps.delivered.iconActive ?? deliveredIcon
      this.steps.delivered.iconInactive =
        params.steps.delivered.iconInactive ?? deliveredIcon

      this.steps.cancel.title =
        params.steps.cancel.title ?? useI18n().t('order.cancelled.title')
      this.steps.cancel.help =
        params.steps.cancel.help ?? useI18n().t('order.cancelled.description')
      this.steps.cancel.icon = params.steps.cancel.icon ?? cancelIcon

      this.subSteps.confirmed.title =
        params.substeps.received.title ?? useI18n().t('order.confirmed')
      this.subSteps.confirmed.icon =
        params.substeps.received.icon ?? checkedSmallIcon

      this.subSteps.picking.title =
        params.substeps.picking.title ?? useI18n().t('order.collecting')
      this.subSteps.picking.icon =
        params.substeps.picking.icon ?? pickingDarkIcon
      this.subSteps.picking.iconActive =
        params.substeps.picking.iconActive ?? pickingDarkIcon
      this.subSteps.picking.iconInactive =
        params.substeps.picking.iconInactive ?? pickingDarkIcon

      this.subSteps.readyForPickup.title =
        params.substeps.readyForPickup.title ?? useI18n().t('order.isReady')
      this.subSteps.readyForPickup.icon =
        params.substeps.readyForPickup.icon ?? storageDarkIcon
      this.subSteps.readyForPickup.iconActive =
        params.substeps.readyForPickup.iconActive ?? storageDarkIcon
      this.subSteps.readyForPickup.iconInactive =
        params.substeps.readyForPickup.iconInactive ?? storageDarkIcon

      this.subSteps.goingToDestination.title =
        params.substeps.goingToDestination.title ??
        useI18n().t('order.isOnItsWay')
      this.subSteps.goingToDestination.icon =
        params.substeps.goingToDestination.icon ?? goingToDestinationDarkIcon
      this.subSteps.goingToDestination.iconActive =
        params.substeps.goingToDestination.iconActive ??
        goingToDestinationDarkIcon
      this.subSteps.goingToDestination.iconInactive =
        params.substeps.goingToDestination.iconInactive ??
        goingToDestinationDarkIcon

      this.subSteps.pickedUp.title =
        params.substeps.pickedUp.title ?? useI18n().t('order.picked')
      this.subSteps.pickedUp.icon =
        params.substeps.pickedUp.icon ?? deliveredLightIcon
      this.subSteps.pickedUp.iconActive =
        params.substeps.pickedUp.iconActive ?? deliveredLightIcon
      this.subSteps.pickedUp.iconInactive =
        params.substeps.pickedUp.iconInactive ?? deliveredLightIcon

      this.subSteps.delivered.title =
        params.substeps.delivered.title ?? useI18n().t('order.delivered')
      this.subSteps.delivered.icon =
        params.substeps.delivered.icon ?? deliveredLightIcon
      this.subSteps.delivered.iconActive =
        params.substeps.delivered.iconActive ?? deliveredLightIcon
      this.subSteps.delivered.iconInactive =
        params.substeps.delivered.iconInactive ?? deliveredLightIcon

      this.homeBar.iconSize = params.homeBar.iconSize ?? '28px'
      this.homeBar.fontSizes.title = params.homeBar.fontSizes.title ?? ''
      this.homeBar.fontSizes.subtitle = params.homeBar.fontSizes.subtitle ?? ''
      this.homeBar.fontSizes.steps = params.homeBar.fontSizes.steps ?? ''
    },

    getCurrentStep(steps: StepOfOrder[], statusOrder: string) {
      if (statusOrder === OrderStatus.CANCELLED) {
        this.currentStep.position = 4
        this.currentStep.title = this.steps.cancel.title
        this.currentStep.description = this.steps.cancel.help
        this.currentStep.icon = this.steps.cancel.icon
        this.currentStep.status = StepStatus.CANCEL
      } else {
        steps.forEach((step: StepOfOrder, index) => {
          if (steps[index].finished || steps[index].inProgress) {
            this.currentStep.position = step.position
            this.currentStep.title = this.steps[step.label].title
            this.currentStep.description = this.steps[step.label].help
            this.currentStep.icon = this.steps[step.label].icon
            this.currentStep.status = step.status
          }
        })
      }
    }
  }
})
