/**
 * Enum for `showOnly` parameter. This parameter allows to to display only the desired component
 * @readonly
 * @enum {string}
 * @property {string} MAP - Is the value for the `showOnly` parameter. This show only the specified component.
 */
export enum ShowOnly {
  MAP = 'map',
  STEPS = 'steps',
  STEPS_OLD = 'stepsOld'
}
