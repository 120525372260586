import type { WebParams } from '@/steps/types/WebParams'
import type { Parameters } from '@/steps/core/domain/Parameters'
import { isTrue } from '@/shared/domain/utils/IsTrue'

export const getParamsWeb = (webParams: WebParams): Parameters => {
  const params: Parameters = {
    token: webParams.token,
    orderId: webParams.job,
    steps: {
      confirmed: {
        title: webParams.stepConfirmed,
        description: webParams.stepConfirmedHelp,
        icons: {
          default: webParams.stepConfirmedIcon
        }
      },
      picking: {
        title: webParams.stepPicking,
        description: webParams.stepPickingHelp,
        icons: {
          default: webParams.stepPickingIcon,
          active: webParams.stepPickingIconActive,
          inactive: webParams.stepPickingIconInactive
        }
      },
      goingToDestination: {
        title: webParams.stepGoingToDestination,
        description: webParams.stepGoingToDestinationHelp,
        icons: {
          default: webParams.stepGoingToDestinationIcon,
          active: webParams.stepGoingToDestinationIconActive,
          inactive: webParams.stepGoingToDestinationIconInactive
        }
      },
      readyForPickup: {
        title: webParams.stepReadyToPickup,
        description: webParams.stepReadyToPickupHelp,
        icons: {
          default: webParams.stepReadyToPickupIcon,
          active: webParams.stepReadyToPickupIconActive,
          inactive: webParams.stepReadyToPickupIconInactive
        }
      },
      pickedUp: {
        title: webParams.stepPickedUp,
        description: webParams.stepPickedUpHelp,
        icons: {
          default: webParams.stepPickedUpIcon,
          active: webParams.stepPickedUpIconActive,
          inactive: webParams.stepPickedUpIconInactive
        }
      },
      delivered: {
        title: webParams.stepDelivered,
        description: webParams.stepDeliveredHelp,
        icons: {
          default: webParams.stepDeliveredIcon,
          active: webParams.stepDeliveredIconActive,
          inactive: webParams.stepDeliveredIconInactive
        }
      },
      cancel: {
        title: webParams.stepCancel,
        description: webParams.stepCancelHelp,
        icons: {
          default: webParams.stepCancelIcon
        }
      }
    },
    theme: {
      backgroundColor: webParams.backgroundColor,
      colorPrimary: webParams.primaryColor,
      colorSecondary: webParams.secondaryColor,
      iconSize: webParams.homeBarIconSize,
      fontFamily: webParams.fontFamily,
      fontSizes: {
        title: webParams.homeBarFontSizeTitle,
        subTitle: webParams.homeBarFontSizeSubtitle,
        stepsTitle: webParams.homeBarFontSizeSteps
      }
    },
    hideElements: {
      reference: isTrue(webParams.hideOrderId),
      deliveryCode: isTrue(webParams.hideDeliveryCode),
      address: !isTrue(webParams.showAddress)
    },
    goingToDestinationOnTransfer: isTrue(
      webParams.goingToDestinationOnTransfer
    ),
    customTexts: {
      title: webParams.stepViewTitle,
      deliveryCode: webParams.deliveryCodeText
    }
  }
  return params
}
