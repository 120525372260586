/**
 * Enum for tags to the `activeTabs` parameter. This parameter allows to customize the App view, selecting what i want to show.
 * @readonly
 * @enum {string}
 * @property {string} STATE_TAB - It is the tag for State Tab in `activeTabs` parameter. This show only the OrderStatus component.
 * @property {string} DETAIL_TAB - It is the tag for Detail Tab parameter in `activeTabs` parameter. This hide OrderHeader component.
 * @property {string} CHAT_TAB - It is the tag for Chat Tab parameter in `activeTabs` parameter. This show only the ChatView component.
 */
export enum ActiveTabParams {
  STATE_TAB = 'stateTab',
  DETAIL_TAB = 'detailTab',
  CHAT_TAB = 'chatTab'
}
