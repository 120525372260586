import type { Parameters } from './Parameters'

export enum StepTypes {
  RECEIVED = 'RECEIVED',
  PICKING = 'PICKING',
  STORAGE = 'STORAGE',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  GOING_TO_DESTINATION = 'GOING_TO_DESTINATION',
  IN_ROUTE = 'IN_ROUTE',
  DELIVERED = 'DELIVERED',
  TRANSFERRING = 'TRANSFERRING',
  PACKING = 'PACKING',
  PICKED_UP = 'PICKED_UP',
  CANCEL = 'CANCEL'
}

export enum OrderStatus {
  CANCELLED = 'CANCELLED',
  CREATED = 'CREATED',
  FINISHED = 'FINISHED'
}

export interface Step {
  taskId: string
  position: number
  label: string
  inProgress: boolean
  finished: boolean
  type: StepTypes
}

export interface Order {
  id: string
  status: OrderStatus
  reference: string
  clientId: string
  steps: {
    one: Step
    two: Step
    three: Step
    four: Step
  }
  eta: {
    from: number
    to: number
  }
  deliveryCode: string
  origin: {
    address: string
  }
  destination: {
    address: string
  }
}

export interface OrderRepository {
  getOrderById: (p: {
    orderId: Order['id']
    goingToDestinationOnTransfer: Parameters['goingToDestinationOnTransfer']
  }) => (callback: (order: Order) => void) => void
}
