import { Loading } from '@/shared/types/Loading'
import type { Order } from './Order'

export interface StepState {
  immutable: {
    order: Order
  }
  loading: {
    loadOrder: Loading
  }
}

export const stepInitialState: StepState = {
  immutable: {
    order: {} as Order
  },
  loading: {
    loadOrder: Loading.IDLE
  }
} as StepState
