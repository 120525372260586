export const getClientId = (token: string) => {
  try {
    const tokens = JSON.parse(import.meta.env.VITE_TOKENS)
    console.log('secrets_tokens', true)

    return tokens[token]
  } catch (e) {
    console.log('secrets_tokens', false)
    return ''
  }
}
