import type { Picking } from './Picking'
import type { PickingApi } from './PickingApi'
import type { Product } from './Product'
import { StepStatusApi } from '@/domain/StepStatusApi'
import type { Order } from './Order'
import { OrderStatus } from './OrderStatus'
import { useOrderStore } from '../stores/OrderStore'
import { formatMoney } from '../shared/utils/formatMoney'
import { ProductServiceImpl } from '@/services/ProductService'

/**
 * Returns the picking adapted from LIVE STATUS API in FirebaseService to Picking domain
 *
 * @param picking - The picking obtained from LIVE STATUS API in FirebaseService
 * @returns {Picking} - The picking adapted from LIVE STATUS API in FirebaseService to Picking domain
 *
 * @function
 */
export const adaptPicking = async function (
  picking: PickingApi,
  hideDecimals: boolean,
  pricesSeparatedByComma: boolean,
  hideOrderLate: boolean,
  order: Order
): Promise<Picking> {
  const toFormatMoney = formatMoney({
    currency: order.paymentInfo.currencyCode,
    hideDecimals,
    pricesSeparatedByComma
  })

  const stepInProgress =
    order.steps.find(
      (step) => step.inProgress === true && step.finished === false
    )?.status ?? ''

  const slotEndDate = new Date((picking.slot_info?.to?.seconds ?? 0) * 1000)
  const showOrderLate =
    new Date() > slotEndDate &&
    order.status !== OrderStatus.CANCELLED &&
    order.status !== OrderStatus.FINISHED &&
    !hideOrderLate

  const productsRemoved = await adaptProducts(
    picking.removed,
    'removed',
    stepInProgress,
    order.pickingIsInProgress,
    toFormatMoney
  )

  return {
    ...picking,
    added: await adaptProducts(
      picking.added,

      'added',
      stepInProgress,
      order.pickingIsInProgress,
      toFormatMoney
    ),
    pending: await adaptProducts(
      picking.pending,

      'pending',
      stepInProgress,
      order.pickingIsInProgress,
      toFormatMoney
    ),
    rejected: await adaptProducts(
      picking.rejected,

      'rejected',
      stepInProgress,
      order.pickingIsInProgress,
      toFormatMoney
    ),
    removed: productsRemoved,
    replacementSuggestions: productsRemoved.products.filter(
      (product) => product.suggestedReplacements.length > 0
    ),
    showOrderLate
  }
}

/**
 * Returns the object of product
 *
 * @returns {object} - The string tag
 *
 * @function
 */
const adaptProducts = async function (
  picking: object,
  /*TODO change name typeList to ProductGroupApi and create enum */
  typeList: string,
  stepInProgress: string,
  pickingIsInProgress: boolean,
  toFormatMoney: (value: number) => string
): Promise<object> {
  const productService = new ProductServiceImpl()
  const orderStore = useOrderStore()
  const products = []
  for await (const productAPI of picking.products) {
    const presentationPrice = toFormatMoney(
      productAPI.presentation.price.amount *
        (typeList === 'added' ? productAPI.quantity_found : productAPI.quantity)
    )

    const suggestedReplacements: Product[] = []
    let replacementSelected = ''

    if (
      typeList === 'removed' &&
      productAPI.replacement_info?.suggested_replacements.length > 0 &&
      productAPI.replacement_info?.replacement_mode === 'SUGGESTED' &&
      productAPI.replacement_info?.replaced_by === null &&
      ((stepInProgress === StepStatusApi.PICKING && pickingIsInProgress) ||
        orderStore.isAuditing)
    ) {
      for await (const term of productAPI.replacement_info
        .suggested_replacements) {
        const product = await productService.getProduct(
          term,
          orderStore.store.reference,
          orderStore.order.source
        )
        if (product !== null && product.presentation.price.amount) {
          product.presentation.quantity = productAPI.quantity
          suggestedReplacements.push(product)
        }
        replacementSelected =
          suggestedReplacements.length === 1 ? suggestedReplacements[0].id : ''
      }
    }
    products.push({
      ...productAPI,
      suggestedReplacements: suggestedReplacements,
      replacementSelected: replacementSelected,
      presentationPrice
    })
  }
  const countProductsToReplace: number =
    typeList === 'removed'
      ? products.filter(
          (product) =>
            product.replacement_info?.suggested_replacements?.length > 0 &&
            product.replacement_info?.replacement_mode === 'SUGGESTED' &&
            product.suggestedReplacements.length > 0
        ).length
      : 0

  const countProductsReplaced: number =
    typeList === 'removed'
      ? products.filter(
          (product) =>
            products.length > 0 &&
            (Boolean(product?.replaced_by) ||
              Boolean(product?.replacement_info?.replaced_by))
        ).length
      : 0

  const countProductsReplacement: number = countProductsToReplace
  return {
    products: products,
    quantity: products.length,
    countProductsToReplace,
    countProductsReplaced,
    countProductsReplacement
  }
}
