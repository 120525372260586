<script setup lang="ts">
import {
  type PropType,
  computed,
  defineAsyncComponent,
  onBeforeMount,
  ref,
  watch
} from 'vue'
import { storeToRefs } from 'pinia'
import type { Params } from './shared/domain/Params'
import type { Parameters } from './steps/core/domain/Parameters'
import { ShowOnly } from './domain/ShowOnly'
import { useAppConfigStore } from './stores/AppConfigStore'
import { sendInitialEventsToGA } from './app/services/ga/sendInitialEventsToGA'
import { addAuditDataToMonitor } from './app/services/monitor/addAuditDataToMonitor'
import { installFont } from '@/app/services/fonts/installFont'
import { getDataToStatsig } from '@/shared/services/featureFlag/utils/getDataToStatsig'
import FeatureFlagProvider from '@/shared/services/featureFlag/FeatureFlagProvider.vue'

import { useChatStore } from './chat/stores/ChatStore'
import { useStepStore } from './stores/StepStore'
import { useOrderStore } from './stores/OrderStore'
import { StatsigType } from './shared/services/featureFlag/types'
import { Order } from '@/steps/core/domain/Order'
import { getOrder } from '@/steps/core/selects'
import { useStepsStore as useStepsStoreV2 } from '@/steps/store'

const LiveStatusView = defineAsyncComponent(
  () => import('./views/LiveStatusView.vue')
)
const ChatView = defineAsyncComponent(() => import('./chat/views/ChatView.vue'))
const MapView = defineAsyncComponent(() => import('./views/MapView.vue'))
const StepsOldView = defineAsyncComponent(() => import('./views/StepsView.vue'))
const StepsView = defineAsyncComponent(
  () => import('./steps/scenes/Home/HomeScene.vue')
)

const props = defineProps({
  params: {
    type: Object as PropType<Params>,
    required: true
  },
  stepsParams: { type: Object as PropType<Parameters>, required: true }
})

const appConfigStore = useAppConfigStore()
const chatStore = useChatStore()
const stepStore = useStepStore()
const orderStore = useOrderStore()
const stepStoreV2 = useStepsStoreV2()
const stepOrder = computed<Order>(() => getOrder(stepStoreV2)())
const statsigData = ref<StatsigType>(getDataToStatsig())
const languageNavigator = props.params.language ?? window.navigator.language
if (languageNavigator === 'ar') {
  appConfigStore.positionOfContent = 'left'
  appConfigStore.positionClassName = 'lsw-direction-rtl'
}

appConfigStore.setStateFromParams(props.params)
chatStore.setStateFromParams(props.params)
stepStore.setStateFromParams(props.params)

const { order, getUser } = storeToRefs(orderStore)

onBeforeMount(() => {
  if (props.params.theme.fontFamily) {
    installFont({ fontFamily: props.params.theme.fontFamily })
  }
})

watch(
  () => order.value.id,
  () => {
    sendInitialEventsToGA({
      order: order.value,
      user: getUser.value,
      params: props.params
    })

    addAuditDataToMonitor({
      user: { name: getUser.value?.name, email: getUser.value?.email },
      tags: [import.meta.env.MODE, order.value.clientId]
    })
    statsigData.value = getDataToStatsig({ clientId: order.value.clientId })
  }
)
watch(
  () => stepOrder.value.id,
  () => {
    statsigData.value = getDataToStatsig({
      clientId: stepOrder.value.clientId
    })
  }
)
</script>

<template>
  <FeatureFlagProvider
    :clientApiKey="statsigData.clientApiKey"
    :user="statsigData.user"
    :options="statsigData.options"
  >
    <div
      :class="`main-content lsw-content ${appConfigStore.positionClassName}`"
      :style="`--primary_color: ${appConfigStore.color.primary}; --secondary_color: ${appConfigStore.color.secondary}; --font-family: ${appConfigStore.fontFamily}`"
      data-test="main-content-test"
    >
      <LiveStatusView
        v-if="!appConfigStore.onlyElements.chat && !appConfigStore.showOnly"
        :env="props.params?.env"
        :job="props.params?.job"
        :token="props.params?.token"
        :fontFamily="props.params?.theme?.fontFamily"
        :primaryColor="props.params?.theme?.primaryColor"
        :secondaryColor="props.params?.theme?.secondaryColor"
        :activeTabs="props.params?.activeTabs"
        :hideOrderDelivery="props.params?.hideElements.orderDelivery"
        :hideOrderHeader="props.params?.hideElements.orderHeader"
        :hideProductsAdd="props.params?.hideElements.productsAdd"
        :hideChatWithDriver="props.params?.hideElements.chatWithDriver"
        :hideChatWithPicker="props.params?.hideElements.chatWithPicker"
        :customTextDeliveryAddress="props.params?.customTexts.deliveryAddress"
        :currencyCode="props.params?.currencyCode"
        :payments="props.params?.payments"
      />
      <ChatView
        v-if="appConfigStore.onlyElements.chat"
        :token="props.params?.token"
        :orderId="props.params?.job"
        :currencyCode="props.params?.currencyCode"
        :payments="props.params?.payments"
        :hideChatWithDriver="props.params?.hideElements.chatWithDriver"
        :hideChatWithPicker="props.params?.hideElements.chatWithPicker"
      />
      <MapView
        v-if="appConfigStore.showOnly === ShowOnly.MAP"
        :token="props.params?.token"
        :job="props.params?.job"
        :currencyCode="props.params?.currencyCode"
        :payments="props.params?.payments"
      />
      <!-- TODO: Remove steps v1 before test of steps v2 -->
      <StepsOldView
        v-if="appConfigStore.showOnly === ShowOnly.STEPS_OLD"
        :token="props.params?.token"
        :job="props.params?.job"
        :currencyCode="props.params?.currencyCode"
        :payments="props.params?.payments"
        :customTextReadyToPickupTitle="props.params.steps.readyToPickup.title"
        :customTextReadyToPickupHelp="props.params.steps.readyToPickup.help"
        :customTextGoingToDestinationTitle="
          props.params.steps.goingToDestination.title
        "
        :customTextGoingToDestinationHelp="
          props.params.steps.goingToDestination.help
        "
      />
      <StepsView
        v-if="appConfigStore.showOnly === ShowOnly.STEPS"
        :params="props.stepsParams"
      />
    </div>
  </FeatureFlagProvider>
</template>

<style>
@import '@/assets/base.css';
.main-content {
  height: 100%;
}
.ant-typography {
  margin-top: 0px !important;
}
</style>
