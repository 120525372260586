/**
 * Recursively flattens an object by concatenating keys with a separator.
 * @param obj - The object to be flattened.
 * @param excludedKeys
 * @param prefix - The prefix to be added to keys.
 * @param separator - The separator between keys.
 * @returns The flattened object.
 */
export const flattenObject = (
  obj: object,
  excludedKeys: string[] = [],
  prefix = '',
  separator = '-'
) => {
  const flatParams: Record<string, unknown> = {}

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key) && !excludedKeys.includes(key)) {
      const value = obj[key]
      if (typeof value === 'object' && value !== null) {
        const flattened = flattenObject(
          value,
          excludedKeys,
          prefix + key + separator,
          separator
        )
        Object.assign(flatParams, flattened)
      } else {
        flatParams[prefix + key] = value
      }
    }
  }

  return flatParams
}

/**
 * Excludes keys with false, undefined, or empty string values from an object.
 * @param obj - The object to be filtered.
 * @returns The filtered object.
 */
export const excludeFalseUndefinedAndEmptyStrings = (
  obj: Record<string, unknown>
) => {
  const filteredObj: Record<string, unknown> = {}

  for (const key in obj) {
    if (
      // eslint-disable-next-line no-prototype-builtins
      obj.hasOwnProperty(key) &&
      obj[key] !== false &&
      obj[key] !== undefined &&
      obj[key] !== ''
    ) {
      filteredObj[key] = obj[key]
    }
  }

  return filteredObj
}
