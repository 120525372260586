import rg4js from 'raygun4js'

interface AddAuditDataToMonitorProps {
  user: {
    name: string
    email: string
  }
  tags: string[]
}
export const addAuditDataToMonitor = ({
  user,
  tags
}: AddAuditDataToMonitorProps) => {
  rg4js('setUser', {
    identifier: user.email,
    fullName: user.name,
    email: user.email,
    isAnonymous: false
  })

  rg4js('withTags', tags)
}
