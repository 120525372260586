/**
 * Interface the payment status.
 * @property {string} translateKey - Indicates the payment status translation key.
 * @property {string} iconUrl - Indicates the payment status icon.
 * @property {string} cssClass - Indicates the payment status css.
 * @property {PaymentStatusType} name - Indicates the payment status name
 */
export interface PaymentStatus {
  translateKey: string
  iconUrl: string
  cssClass: string
  name: PaymentStatusType
}

/**
 * Enum for tags to the payment status. It allows to identify the order's payment status.
 * @readonly
 * @enum {string}
 * @property {string} SUCCESS - Indicates a successful payment
 * @property {string} FAILED - Indicates a failed money payment or refund
 * @property {string} CHANGED - Indicates that the payment method has changed
 * @property {string} REVERTED - Indicates a successful refund (Online payment)
 * @property {string} IN_PROGRESS - Indicates that the payment is in progress
 */
export enum PaymentStatusType {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CHANGED = 'CHANGED',
  REVERTED = 'REVERTED',
  SUCCEEDED = 'SUCCEEDED',
  IN_PROGRESS = 'IN_PROGRESS'
}
