/**
 * Enum for tags to the step status
 * @readonly
 * @enum {string}
 * @property {string} RECEIVED - It is the tag for the step when the store accept the order
 * @property {string} PICKING - It is the tag for the step when the picker is assigned and arrives at the store
 * @property {string} STORAGE - It is the tag for the stpe when products picking is finished and storage by picker
 * @property {string} READY_FOR_PICKUP - It is the tag for the step when the order is ready for pickup by customer or driver
 * @property {string} GOING_TO_DESTINATION - It is the tag of the step when the order was picked up and is on its way to its destination
 * // TODO - Pending: What is the difference between GOING_TO_DESTINATION and IN_ROUTE status
 * @property {string} IN_ROUTE - It is the tag for "In route" step
 * @property {string} DELIVERED - It is the tag for the step when order is delivered to a client and finished
 * @property {string} TRANSFERRING - It is the tag to identify the status between different steps and depend of the operative model
 * @example
 * Transferring to storage from picking or transferring delivery from storage in "Pick & Delivery with Storage" model
 * @property {string} PACKING - It is the tag for the stpe when products picking is finished and packing by picker
 */
export enum StepStatusApi {
  RECEIVED = 'RECEIVED',
  PICKING = 'PICKING',
  STORAGE = 'STORAGE',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  GOING_TO_DESTINATION = 'GOING_TO_DESTINATION',
  IN_ROUTE = 'IN_ROUTE',
  DELIVERED = 'DELIVERED',
  TRANSFERRING = 'TRANSFERRING',
  PACKING = 'PACKING',
  PICKED_UP = 'PICKED_UP',
  CANCEL = 'CANCEL'
}
