<script setup lang="ts">
import { onBeforeMount, provide, ref, readonly, watch } from 'vue'
import statsig from 'statsig-js'
import { FEATURE_FLAG_KEY } from './constants'

const props = defineProps(['clientApiKey', 'user', 'options'])
const initialized = ref<boolean>(false)

onBeforeMount(async () => {
  await statsig.initialize(props.clientApiKey, props.user, props.options)
})

watch(
  () => props.user,
  async () => {
    await statsig.updateUser(props.user)
    initialized.value = true
  }
)

provide(FEATURE_FLAG_KEY, { initialized: readonly(initialized) })
</script>
<template>
  <slot />
</template>
