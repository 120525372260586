import { createPinia } from 'pinia'
import VueGtag from 'vue-gtag'
import { buildPluginI18n } from '@/plugins/i18n'
import type { WebParams } from '@/shared/domain/WebParams'
import type { WebParams as WebStepsParams } from '@/steps/types/WebParams'
import type { LibParams } from '@/shared/domain/LibParams'
import type { LibParams as LibStepsParams } from '@/steps/types/LibParams'
import { initializeMonitor } from '@/app/services/monitor/initializeMonitor'
import { initializeClarity } from '@/app/services/clarity/initializeClarity'

const CLARITY_API_KEY = 'hnxbnbfu49' // TODO:
const GOOGLE_ANALYTICS_API_KEY = 'G-27PT2L3W6Z' // TODO:

const shouldEnableMonitor = import.meta.env.VITE_APP_ENV === 'production'
const shouldEnableGA = import.meta.env.VITE_APP_ENV === 'production'
const shouldEnableClarity = import.meta.env.VITE_APP_ENV === 'production'

interface InitializeServicesProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  app: any
  params: LibParams | WebParams | LibStepsParams | WebStepsParams
}

export const initializeServices = ({
  app,
  params
}: InitializeServicesProps) => {
  app.use(createPinia())
  app.use(buildPluginI18n(params.language))

  if (shouldEnableMonitor) {
    initializeMonitor({
      apiKey: import.meta.env.VITE_RAYGUN_API_KEY,
      tags: [import.meta.env.MODE],
      customData: { params }
    })
  }

  if (shouldEnableGA) {
    app.use(VueGtag, { config: { id: GOOGLE_ANALYTICS_API_KEY } })
  }

  if (shouldEnableClarity) {
    initializeClarity({ apiKey: CLARITY_API_KEY })
  }
}
