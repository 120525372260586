/* eslint-disable @typescript-eslint/no-explicit-any */
export const getShouldShowProductsReplacement = (tasks: any[]): boolean => {
  if (tasks && Array.isArray(tasks)) {
    const consolidationStep = tasks.find(
      (task) => task?.type === 'CONSOLIDATION'
    )

    if (
      consolidationStep &&
      ['DOING', 'PROCESSING'].includes(consolidationStep.state)
    ) {
      const auditingStep = consolidationStep.steps.find(
        (step: any) => step.type === 'AUDITING'
      )

      return auditingStep && ['PENDING', 'DOING'].includes(auditingStep.state)
    }
  }

  return false
}
