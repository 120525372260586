/**
 * Enum for groups to the channel type in chat. It is used to adapt from API to local domain
 * @readonly
 * @enum {string}
 * @property {string} SUPPORT - It is the enum for User to Support chat channel
 * @property {string} PICKING - It is the enum for User to Shopper chat channel
 * @property {string} RESOURCE - It is the enum for Support to Shopper chat channel
 */
export enum ChatGroup {
  PICKING = 'PICKING',
  SUPPORT = 'SUPPORT',
  RESOURCE = 'RESOURCE'
}
