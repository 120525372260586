interface FormatMoneyInput {
  currency: string
  hideDecimals: boolean
  pricesSeparatedByComma: boolean
}

type FormatMoneyOutput = (value: number) => string

export const formatMoney = ({
  currency = 'COP',
  hideDecimals = false,
  pricesSeparatedByComma = false
}: FormatMoneyInput): FormatMoneyOutput => {
  // TODO: This configuration should be per client
  const locale = 'es-CO'
  const currencyFormat = {
    style: 'currency',
    currency,
    minimumFractionDigits: hideDecimals ? 0 : 2,
    maximumFractionDigits: hideDecimals ? 0 : 2
  }

  const formatSeparator = (value: string) =>
    pricesSeparatedByComma
      ? value
          .replace('.', '@')
          .replace(',', '.')
          .replace('@', ',')
          .replace('$', '')
          .replace(/([a-zA-Z])/g, '')
      : value.replace('$', '').replace(/([a-zA-Z])/g, '')

  const removeCurrency = (value: string): string => value.replace(currency, '')

  return (number: number): string => {
    let result = ''
    try {
      result = new Intl.NumberFormat(locale, currencyFormat)
        .format(number)
        .replace(/\s/g, ' ')
    } catch (e) {
      result = number?.toString() ?? ''
    }

    return removeCurrency(formatSeparator(result))
  }
}
