export const isTrue = (value: string | boolean): boolean => {
  if (typeof value === 'undefined') {
    return false
  }

  if (typeof value === 'string') {
    const trimmedValue = value.replace(/^['"](.*)['"]$/, '$1')
    return trimmedValue.toLowerCase() === 'true'
  }

  return value === true
}
