/**
 * Enum for tags to handle the order status.
 * @readonly
 * @enum {string}
 * @property {string} CANCELLED - It is the tag for order canceled status and it means the customer has canceled the order.
 * * @property {string} CREATED - It is the tag for order created status and it means the order is confirmed.
 */
export enum OrderStatus {
  CANCELLED = 'CANCELLED',
  CREATED = 'CREATED',
  FINISHED = 'FINISHED'
}
