import { formatMessage } from '../shared/utils/FormatMessageUtil'
import type { Product } from '../domain/Product'
import type { ProductError } from '../domain/ProductError'
import type { ProductPostDto } from '../domain/ProductPostDto'

export interface ProductRepository {
  putProducts: (products: Product[]) => Promise<ProductError[]>
  deleteProduct: (productId: string) => Promise<boolean>
  getProduct: (
    term: string,
    storeReference: string,
    source: string
  ) => Promise<Product>
  searchProducts: (
    searchTerm: string,
    storeReference: string,
    source: string
  ) => Promise<Product[]>
  postProduct: (product: Product) => Promise<string>
}

export class ProductRepositoryImpl implements ProductRepository {
  private apiKey: string
  private firebaseToken: string

  constructor({
    apiKey,
    firebaseToken
  }: {
    apiKey: string
    firebaseToken: string
  }) {
    this.apiKey = apiKey
    this.firebaseToken = firebaseToken
  }

  public async putProducts(products: Product[]): Promise<ProductError[]> {
    try {
      const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'access-token': localStorage.getItem('IL_token') as string
      })

      const body = JSON.stringify({ productsToUpdate: products })
      const options = {
        method: 'PUT',
        headers,
        body
      }
      const request = await fetch(
        `${import.meta.env.VITE_JOB_TRACKING_API_URL}/products/`,
        options
      )
      const response = await request.json()
      return response.data.error as ProductError[]
    } catch (error) {
      console.error(
        formatMessage(ProductRepositoryImpl.name, this.putProducts.name, error)
      )
    }
  }

  public async deleteProduct(productId: string): Promise<boolean> {
    try {
      const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': this.apiKey,
        'access-token': localStorage.getItem('IL_token') as string
      })

      const options = {
        method: 'DELETE',
        headers,
        body: JSON.stringify({ itemId: productId })
      }

      const request = await fetch(
        `${import.meta.env.VITE_JOB_TRACKING_API_URL}/products`,
        options
      )
      const response = await request.json()
      return response.data.isDeleted
    } catch (error) {
      console.error(
        formatMessage(
          ProductRepositoryImpl.name,
          this.deleteProduct.name,
          error
        )
      )
    }
    return false
  }

  public async getProduct(
    term: string,
    storeReference: string,
    source: string
  ): Promise<Product> {
    try {
      const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': this.apiKey,
        'access-token': localStorage.getItem('IL_token') as string
      })

      const options = {
        method: 'get',
        headers
      }

      const request = await fetch(
        `${
          import.meta.env.VITE_JOB_TRACKING_API_URL
        }/products/?storeReference=${storeReference}&term=${term}&source=${source}`,
        options
      )
      const response = await request.json()
      return response.data as Product
    } catch (error) {
      console.error(
        formatMessage(ProductRepositoryImpl.name, this.getProduct.name, error)
      )
    }
  }

  public async searchProducts(
    searchTerm: string,
    storeReference: string,
    source: string
  ): Promise<Product[]> {
    try {
      const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': this.apiKey,
        'access-token': localStorage.getItem('IL_token') as string
      })

      const options = {
        method: 'POST',
        headers,
        body: JSON.stringify({ name: searchTerm, storeReference, source })
      }

      const request = await fetch(
        `${import.meta.env.VITE_JOB_TRACKING_API_URL}/products/search`,
        options
      )
      const response = await request.json()
      return response.data as Product[]
    } catch (error) {
      console.error(
        formatMessage(
          ProductRepositoryImpl.name,
          this.searchProducts.name,
          error
        )
      )
    }
  }

  public async postProduct(product: Product): Promise<string> {
    try {
      const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'access-token': localStorage.getItem('IL_token') as string
      })

      const productPostDto: ProductPostDto = {
        currency_code: product.presentation.price.currency_code,
        item_to_add: {
          id: product.id,
          name: product.name,
          photo_url: product.photo_url,
          unit: product.presentation.unit,
          sub_unit: product.presentation.sub_unit,
          quantity: product.quantity,
          sub_quantity: product.presentation.sub_quantity,
          price: product.presentation.price.amount,
          attributes: product.attributes,
          price_details: product.price_details,
          barcodes: product.barcodes,
          comment: ''
        }
      }
      const body = JSON.stringify({ productToAdd: productPostDto })
      const options = {
        method: 'POST',
        headers,
        body
      }
      const request = await fetch(
        `${import.meta.env.VITE_JOB_TRACKING_API_URL}/products/`,
        options
      )
      const response = await request.json()
      return response.data.id as string
    } catch (error) {
      console.error(
        formatMessage(ProductRepositoryImpl.name, this.postProduct.name, error)
      )
    }
  }
}
