/**
 * Represents the type of member in the chat.
 * @readonly
 * @enum {string}
 * @property {string} USER - It is the type for "User" member
 * @property {string} SHOPPER - It is the tag for "Shopper" member
 * @property {string} SUPPORT - It is the tag for "Support" member
 * @property {string} NONE - This tag represents the absence of type
 */
export enum ChatMemberType {
  USER = 'user',
  SHOPPER = 'shopper',
  SUPPORT = 'support',
  NONE = 'none'
}
