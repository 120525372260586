import { defineStore } from 'pinia'
import type { Picking } from '../domain/Picking'
import type { Order } from '@/domain/Order'
import { PaymentStatusType } from '@/domain/PaymentStatus'
import type { ProductToUpdate } from '@/domain/ProductToUpdate'
import { UserType } from '@/domain/UserType'
import type Contactless from '@/domain/Contactless'
import { ContactlessType } from '@/domain/ContactlessType'
import type User from '@/domain/User'
import { onPickingSnapshot } from '@/services/firebase/onPickingSnapshot'
import { getShouldShowProductsReplacement } from '@/stores/getShouldShowProductsReplacement'
import { OrderStatus } from '@/domain/OrderStatus'
import { adaptPicking } from '@/domain/PickingAdapter'

export const useOrderStore = defineStore({
  id: 'order',
  state: () => ({
    formatCurrency: {},
    order: <Order>{
      clientId: '',
      source: '',
      comment: '',
      shouldShowProductsReplacement: false,
      createdAt: { seconds: 0 },
      deliveryCode: '',
      deliveryOptions: [],
      destination: {
        location: {
          _lat: 0,
          _long: 0
        }
      },
      id: '',
      lastUpdated: new Date(),
      origin: {
        location: {
          _lat: 0,
          _long: 0
        }
      },
      eta: {
        from: 0,
        to: 0
      },
      paymentInfo: {
        prices: {
          discounts: 0,
          orderValue: 0,
          shippingFee: 0,
          subtotal: 0,
          taxes: 0
        },
        currencyCode: '',
        payment: {
          paymentStatus: null,
          value: ''
        }
      },
      payment: {
        method: { name: '', iconUrl: '' },
        status: {
          translateKey: '',
          iconUrl: '',
          cssClass: '',
          name: PaymentStatusType.IN_PROGRESS
        }
      },
      reference: '',
      status: '',
      steps: [
        {
          finished: false,
          inProgress: false,
          finishedAt: { seconds: 0 },
          label: '',
          status: ''
        },
        {
          finished: false,
          inProgress: false,
          finishedAt: { seconds: 0 },
          label: '',
          status: ''
        },
        {
          finished: false,
          inProgress: false,
          finishedAt: { seconds: 0 },
          label: '',
          status: ''
        },
        {
          finished: false,
          inProgress: false,
          finishedAt: { seconds: 0 },
          label: '',
          status: ''
        }
      ],
      tasks: [],
      tracking: '',
      users: [],
      token: '',
      user: <User>{}
    },
    picking: {
      added: { quantity: 0, products: [{ photo_url: '' }] },
      id: '',
      pending: { quantity: 0, products: [{ photo_url: '' }], state: '' },
      rejected: { quantity: 0, products: [{ photo_url: '' }], state: '' },
      removed: { quantity: 0, products: [{ photo_url: '' }], state: '' },
      store_reference: '',
      tag: '',
      replacementSuggestions: {},
      showOrderLate: false
    },
    productsToUpdate: <ProductToUpdate[]>[],
    isVisibleDrawer: false,
    isVisibleDrawerReplacement: false,
    isVisibleDrawerAddProduct: false,
    isVisibleFormDeliveryCurbside: false,
    isVisibleModalDownloadInvoice: false,
    contactless: <Contactless>{
      type: ContactlessType.LEAVE_AT_DOOR,
      receptor: {
        name: '',
        phone: ''
      }
    },
    isContactlessVisibleModal: false,
    isAuditing: false,
    operationalModel: '',
    store: {
      id: '',
      reference: ''
    }
  }),
  actions: {
    async updateOrder(
      order: Order,
      hideDecimal: boolean,
      pricesSeparetedByComma: boolean,
      hideOrderLate: boolean
    ) {
      this.order = order
      if (this.order.status !== OrderStatus.FINISHED) {
        onPickingSnapshot({ jobId: this.order.id })
      } else {
        this.updatePicking(
          await adaptPicking(
            this.picking,
            hideDecimal,
            pricesSeparetedByComma,
            hideOrderLate,
            this.order
          )
        )
      }
      console.log('update_picking_from_update_order_2', this.picking)
    },
    updateOrderFromNebula({ order }: unknown) {
      this.isAuditing = getShouldShowProductsReplacement(order?.tasks)
      this.store = order.store
      this.order.deliveryOptions = order.deliveryOptions
      this.operationalModel = order.operationalModel
      this.picking = order.picking
    },
    updatePicking(picking: Picking) {
      this.picking = picking
    }
  },
  getters: {
    getUser: (state) =>
      state.order.users.find((user) => user.type === UserType.CLIENT)
  }
})
