/**
 * Enum for tabs to the channel type in chat. It is used to handle the chat tabs in chat component
 * @readonly
 * @enum {string}
 * @property {string} SUPPORT - It is the enum for User to Support chat tab
 * @property {string} PICKING - It is the enum for User to Shopper chat tab
 */
export enum ChatTab {
  SUPPORT = 'support',
  PICKING = 'picking'
}
