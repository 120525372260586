import { initializeApp, type FirebaseApp } from 'firebase/app'
import { getFirestore, type Firestore } from 'firebase/firestore'

const firebaseConfig = JSON.parse(import.meta.env.VITE_SHOPPER_FIREBASE_CONFIG)

export class FirebaseConnection {
  private static firebaseInstance: FirebaseApp
  private static firestoreInstance: Firestore

  private constructor() {
    FirebaseConnection.firebaseInstance = initializeApp(firebaseConfig)
    FirebaseConnection.firestoreInstance = getFirestore(
      FirebaseConnection.firebaseInstance
    )
  }

  public static getInstance(): FirebaseApp {
    if (!FirebaseConnection.firebaseInstance) {
      try {
        new FirebaseConnection()
      } catch (error) {
        console.error(error)
      }
    }
    return FirebaseConnection.firebaseInstance
  }

  public static getFirestore(): Firestore {
    if (!FirebaseConnection.firestoreInstance) {
      try {
        new FirebaseConnection()
      } catch (error) {
        console.error(error)
      }
    }
    return FirebaseConnection.firestoreInstance
  }
}
