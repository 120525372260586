import { formatMessage } from '../shared/utils/FormatMessageUtil'
import {
  ProductRepositoryImpl,
  type ProductRepository
} from '@/repositories/ProductRepository'
import type { Product } from '../domain/Product'
import type { ProductError } from '../domain/ProductError'
import { useAppConfigStore } from '@/stores/AppConfigStore'

export interface ProductService {
  putProducts: (products: Product[]) => Promise<ProductError[]>
  deleteProduct: (productId: string) => Promise<boolean>
  getProduct: (
    term: string,
    storeReference: string,
    source: string
  ) => Promise<Product>
  searchProducts: (
    searchTerm: string,
    storeReference: string,
    source: string
  ) => Promise<Product[]>
  postProduct: (product: Product) => Promise<string>
}
export class ProductServiceImpl implements ProductService {
  private readonly productRepository: ProductRepository

  constructor() {
    const appConfigStore = useAppConfigStore()
    this.productRepository = new ProductRepositoryImpl({
      firebaseToken: appConfigStore.firebase.idToken,
      apiKey: appConfigStore.token
    })
  }

  public async putProducts(products: Product[]): Promise<ProductError[]> {
    try {
      const productsError = await this.productRepository.putProducts(products)
      return productsError
    } catch (error) {
      console.error(
        formatMessage(ProductServiceImpl.name, this.putProducts.name, error)
      )
      return [] as Product[]
    }
  }

  public async deleteProduct(productId: string): Promise<boolean> {
    try {
      const isDeleted = await this.productRepository.deleteProduct(productId)
      return isDeleted
    } catch (error) {
      console.error(
        formatMessage(ProductServiceImpl.name, this.deleteProduct.name, error)
      )
      return false
    }
  }

  public async getProduct(
    term: string,
    storeReference: string,
    source: string
  ): Promise<Product> {
    try {
      const product = await this.productRepository.getProduct(
        term,
        storeReference,
        source
      )
      return product
    } catch (error) {
      console.error(
        formatMessage(ProductServiceImpl.name, this.getProduct.name, error)
      )
    }
  }

  public async searchProducts(
    searchTerm: string,
    storeReference: string,
    source: string
  ): Promise<Product[]> {
    try {
      const products = await this.productRepository.searchProducts(
        searchTerm,
        storeReference,
        source
      )
      return products
    } catch (error) {
      console.error(
        formatMessage(ProductServiceImpl.name, this.searchProducts.name, error)
      )
    }
  }

  public async postProduct(product: Product): Promise<string> {
    try {
      const productId: string =
        await this.productRepository.postProduct(product)
      return productId
    } catch (error) {
      console.error(
        formatMessage(ProductServiceImpl.name, this.postProduct.name, error)
      )
    }
  }
}
