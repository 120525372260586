import { createI18n } from 'vue-i18n'
import {
  dateTimeFormats,
  getLanguageOfNavigator
} from '../shared/utils/LocaleUtil'
import es from '../locales/es.json'
import en from '../locales/en.json'
import pt from '../locales/pt.json'
import ptBR from '../locales/pt-br.json'
import pl from '../locales/pl.json'
import it from '../locales/it.json'
import ar from '../locales/ar.json'
import ca from '../locales/ca.json'

const buildPluginI18n = (language?: string) => {
  const pluginI18n = createI18n({
    legacy: false,
    datetimeFormats: dateTimeFormats,
    locale: language === undefined ? getLanguageOfNavigator() : language,
    fallbackLocale: 'es',
    messages: {
      es: es,
      'es-419': es,
      en: en,
      pt: pt,
      'pt-BR': ptBR,
      pl: pl,
      it: it,
      ar: ar,
      ca: ca
    }
  })

  return pluginI18n
}

export { buildPluginI18n }
