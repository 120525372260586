interface InstallFontProps {
  fontFamily: string
}

export const installFont = ({ fontFamily }: InstallFontProps) => {
  const fontLink = document.createElement('link')
  fontLink.href = `https://fonts.googleapis.com/css2?family=${fontFamily}&display=swap`
  fontLink.rel = 'stylesheet preload prefetch'
  document.head.appendChild(fontLink)
}
