export const signedUrls =
  (accessToken: string) =>
  async (imagesUrls: string[]): Promise<string[]> => {
    try {
      const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'access-token': accessToken
      })

      const request = await fetch(
        `${import.meta.env.VITE_JOB_TRACKING_API_URL}/sign-image`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify({ imagesUrls: imagesUrls })
        }
      )
      const response = await request.json()
      return response.data
    } catch (error) {
      console.error('ERROR-signUrl: ', error)
    }

    return []
  }
