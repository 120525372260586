import { event } from 'vue-gtag'
import {
  flattenObject,
  excludeFalseUndefinedAndEmptyStrings
} from '@/shared/utils/ObjectUtil'

const sendHomeViewEvent = ({ order, user }) => {
  event('home_view', {
    event_label: 'User lands',
    client_name: order.clientId,
    storeName: order.origin.name,
    order_id: order.id,
    email: user.email,
    name: user.name,
    phone: user.phone
  })
}

const sendParamsEvents = ({ order, user, params }) => {
  const excludedKeys = ['token', 'job']
  const flatParams = flattenObject(params, excludedKeys)
  const filteredParams = excludeFalseUndefinedAndEmptyStrings(flatParams)

  for (const key in filteredParams) {
    // eslint-disable-next-line no-prototype-builtins
    if (filteredParams.hasOwnProperty(key)) {
      const value = filteredParams[key]
      event(`${key}`, {
        event_label: key,
        value: value,
        client_name: order.clientId,
        order_id: order.id,
        email: user.email,
        name: user.name,
        phone: user.phone,
        storeName: order.origin.name
      })
    }
  }
}

export const sendInitialEventsToGA = ({ order, user, params }) => {
  sendHomeViewEvent({ order, user })
  sendParamsEvents({ order, user, params })
}
