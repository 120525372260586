import { createApp } from 'vue'
import App from './App.vue'
import type { WebParams } from './shared/domain/WebParams'
import type { WebParams as WebStepsParams } from '@/steps/types/WebParams'
import type { LibParams } from './shared/domain/LibParams'
import type { LibParams as LibStepsParams } from '@/steps/types/LibParams'
import {
  adaptFromLibParams,
  adaptFromWebParams
} from './shared/domain/ParamsAdapter'
import { getParamsLib } from '@/steps/utils/getParamsLib'
import { getParamsWeb } from '@/steps/utils/getParamsWeb'
import { extractParamsFromUrl } from './shared/utils/UrlUtil'
import { initializeServices } from '@/app/utils/initializeServices'

console.log('build_mode ', import.meta.env.MODE)

const renderLibraryApp = (params: LibParams | LibStepsParams) => {
  console.log('params_lib_mode', { ...params, token: 'hidden' })

  const libraryApp = createApp(App, {
    params: adaptFromLibParams(params as LibParams),
    stepsParams: getParamsLib(params as LibStepsParams)
  })
  initializeServices({ app: libraryApp, params })
  return libraryApp.mount(params.container)
}

const renderWebApp = () => {
  const params = extractParamsFromUrl<WebParams | WebStepsParams>()
  console.log('params_web_mode', { ...params, token: 'hidden' })

  const webApp = createApp(App, {
    params: adaptFromWebParams(params as WebParams),
    stepsParams: getParamsWeb(params as WebStepsParams)
  })
  initializeServices({ app: webApp, params })
  webApp.mount('#app')
}

if (import.meta.env.MODE === 'web') {
  renderWebApp()
}

export default {
  lsw: {
    render: renderLibraryApp
  }
}
