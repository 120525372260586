/* eslint-disable @typescript-eslint/no-explicit-any */
import { doc, onSnapshot } from 'firebase/firestore'
import { useOrderStore } from '@/stores/OrderStore'
import { useAppConfigStore } from '@/stores/AppConfigStore'

import type { PickingApi } from '@/domain/PickingApi'
import { adaptPicking } from '@/domain/PickingAdapter'

import { FirebaseConnection } from '@/shared/services/firebase/FirebaseConnection'

interface OnPickingSnapshotProps {
  jobId: string
}

export const onPickingSnapshot = ({ jobId }: OnPickingSnapshotProps) => {
  try {
    const firestore = FirebaseConnection.getFirestore()
    const orderStore = useOrderStore()
    const appConfigStore = useAppConfigStore()
    onSnapshot(
      doc(firestore, import.meta.env.VITE_FIRESTORE_PICKING_COLLECTION, jobId),
      async (document) => {
        let picking: PickingApi
        if (document.data()) {
          picking = document.data() as PickingApi
        } else {
          picking = orderStore.picking as any
        }
        console.log('update_picking', picking)
        orderStore.updatePicking(
          await adaptPicking(
            picking,
            appConfigStore.hideElements.decimals,
            appConfigStore.pricesSeparetedByComma,
            appConfigStore.hideElements.orderLate,
            orderStore.order
          )
        )
      }
    )
  } catch (error) {
    console.error('error_get_updates_picking', error)
    throw error
  }
}
