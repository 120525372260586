import { type StatsigType } from '../types'

const parseTier = (env: string) => {
  if (env === 'production') return 'production'
  if (env === 'xandar') return 'staging'
  return 'development'
}

type GetDataToStatsigType = (p?: { clientId?: string }) => StatsigType

export const getDataToStatsig: GetDataToStatsigType = ({ clientId } = {}) => {
  const clientApiKey = import.meta.env.VITE_STATSIG_API_KEY
  const options = {
    environment: { tier: parseTier(import.meta.env.VITE_APP_ENV) }
  }

  if (clientId) {
    const user = {
      custom: {
        clientId: clientId
      }
    }
    return { clientApiKey, options, user }
  }

  return { clientApiKey, options }
}
