import { ChatTab } from '../../chat/domain/ChatTab'
import type { Params } from './Params'
import type { WebParams } from '@/shared/domain/WebParams'
import type { LibParams } from '@/shared/domain/LibParams'
import { getClientId } from '../utils/getClientId'
import { isTrue } from './utils/IsTrue'

const getParamsFromSecret = (clientId: string): Partial<Params> => {
  try {
    const params = JSON.parse(
      import.meta.env[`VITE_CLIENT_${clientId.toUpperCase()}`]
    )

    console.log('secrets_params', params)
    return params
  } catch (error) {
    console.log('secrets_params', false)
    return {}
  }
}

/**
 * Returns the app's params to be use in web mode
 *
 * @param {WebParams} webParams - The params object obtained from the URL
 * @returns {Params} - The params object for web mode
 *
 * @function
 */
const adaptFromWebParams = (webParams: WebParams): Params => {
  const clientId = getClientId(webParams.token)
  const secretParams = clientId ? getParamsFromSecret(clientId) : {}

  const params: Params = {
    ...webParams,
    theme: {
      fontFamily: webParams.fontFamily,
      primaryColor: webParams.primaryColor,
      secondaryColor: webParams.secondaryColor
    },
    substeps: {
      received: {
        title: webParams.received,
        icon: webParams.subStepConfirmedIcon
      },
      picking: {
        title: webParams.picking,
        icon: webParams.subStepPickingIcon,
        iconInactive: webParams.subStepPickingIconInactive,
        iconActive: webParams.subStepPickingIconActive
      },
      goingToDestination: {
        title: webParams.goingToDestination,
        icon: webParams.subStepGoingToDestinationIcon,
        iconActive: webParams.subStepGoingToDestinationIconActive,
        iconInactive: webParams.subStepGoingToDestinationIconInactive
      },
      delivered: {
        title: webParams.delivered,
        icon: webParams.subStepDeliveredIcon,
        iconActive: webParams.subStepDeliveredIconActive,
        iconInactive: webParams.subStepDeliveredIconInactive
      },
      readyForPickup: {
        title: webParams.readyForPickup,
        icon: webParams.subStepReadyToPickupIcon,
        iconActive: webParams.subStepReadyToPickupIconActive,
        iconInactive: webParams.subStepReadyToPickupIconInactive
      },
      pickedUp: {
        title: webParams.subStepPickedUp,
        icon: webParams.subStepPickedUpIcon,
        iconActive: webParams.subStepPickedUpIconActive,
        iconInactive: webParams.subStepPickedUpIconInactive
      }
    },
    hideElements: {
      orderHeader: !isTrue(webParams.header ?? true),
      orderDelivery: !isTrue(webParams.originDestination ?? true),
      deliveryCode: isTrue(webParams.hideDeliveryCode),
      productQuantitiesChange: isTrue(webParams.shouldChangeQuantitiesProducts),
      chatWithDriver: isTrue(webParams.hideChatWithDriver),
      productDelete: isTrue(webParams.shouldDeleteProducts),
      rateOrder: !isTrue(webParams.canRate),
      paymentStatus: isTrue(webParams.hidePaymentStatus),
      orderMap: isTrue(webParams.hideOrderMap),
      helpCenter: isTrue(webParams.hideHelpCenter),
      decimals: isTrue(webParams.hideDecimals),
      taxes: isTrue(webParams.hideTaxes),
      orderHelp: isTrue(webParams.hideOrderHelp),
      orderTransactions: isTrue(webParams.hideOrderTransactions),
      cancelOrder: isTrue(webParams.hideCancelOrder),
      changeDeliveryDate: !isTrue(webParams.canChangeDeliveryDate),
      products: isTrue(webParams.hideProducts),
      orderId: isTrue(webParams.hideOrderId),
      shipping: isTrue(webParams.hideShipping),
      discounts: isTrue(webParams.hideDiscounts),
      orderDetail: isTrue(webParams.hideOrderDetail),
      paymentMethod: isTrue(webParams.hidePaymentMethod),
      commentHelpText: isTrue(webParams.hideCommentHelpText),
      productsAdd: isTrue(webParams.shouldAddProducts),
      orderLate:
        isTrue(webParams.hideOrderLate) ||
        webParams.hideOrderLate === undefined ||
        webParams.hideOrderLate === null,
      chatWithPicker: isTrue(webParams.hideChatWithPicker),
      comments: isTrue(webParams.hideComments),
      chatWithSupport: isTrue(webParams.hideChatWithSupport),
      curbsideParkingPlace: isTrue(webParams.hideCurbsideParkingPlace),
      changeProductsReplacementSuggestions: isTrue(
        webParams.hideChangeProductsReplacementSuggestions
      )
    },
    chat: {
      hideTab: getChatTabHide(webParams.chats),
      defaultTab: getChatDefaultTab(webParams.chatView) || ChatTab.SUPPORT,
      supportTab: {
        title: webParams.chatSupportTabTitle,
        helpStartTime: webParams.chatSupportTabHelpStartTime,
        helpEndTime: webParams.chatSupportTabHelpEndTime
      },
      pickingTab: {
        title: webParams.chatPickingTabTitle
      },
      driverTab: {
        title: webParams.chatDriverTabTitle
      }
    },
    steps: {
      confirmed: {
        title: webParams.stepConfirmed,
        help: webParams.stepConfirmedHelp,
        icon: webParams.stepConfirmedIcon
      },
      picking: {
        title: webParams.stepPicking,
        help: webParams.stepPickingHelp,
        icon: webParams.stepPickingIcon,
        iconActive: webParams.stepPickingIconActive,
        iconInactive: webParams.stepPickingIconInactive
      },
      goingToDestination: {
        title: webParams.stepGoingToDestination,
        help: webParams.stepGoingToDestinationHelp,
        icon: webParams.stepGoingToDestinationIcon,
        iconActive: webParams.stepGoingToDestinationIconActive,
        iconInactive: webParams.stepGoingToDestinationIconInactive
      },
      readyToPickup: {
        title: webParams.stepReadyToPickup,
        help: webParams.stepReadyToPickupHelp,
        icon: webParams.stepReadyToPickupIcon,
        iconActive: webParams.stepReadyToPickupIconActive,
        iconInactive: webParams.stepReadyToPickupIconInactive,
        images: webParams.stepReadyToPickupImages
          ? webParams.stepReadyToPickupImages.split(',')
          : []
      },
      pickedUp: {
        title: webParams.stepPickedUp,
        help: webParams.stepPickedUpHelp,
        icon: webParams.stepPickedUpIcon,
        iconActive: webParams.stepPickedUpIconActive,
        iconInactive: webParams.stepPickedUpIconInactive
      },
      delivered: {
        title: webParams.stepDelivered,
        help: webParams.stepDeliveredHelp,
        icon: webParams.stepDeliveredIcon,
        iconActive: webParams.stepDeliveredIconActive,
        iconInactive: webParams.stepDeliveredIconInactive
      },
      cancel: {
        title: webParams.stepCancel,
        help: webParams.stepCancelHelp,
        icon: webParams.stepCancelIcon
      }
    },
    orderHelpHtml: webParams.orderHelpHtml,
    customTexts: {
      orderStatus: webParams.orderStatusText,
      deliveryCode: webParams.deliveryCodeText,
      deliveryDate: webParams.deliveryDateText,
      deliveryAddress: webParams.deliveryAddressText,
      products: webParams.productsText,
      helpCenter: webParams.helpCenterText,
      orderDetail: webParams.orderDetailText,
      paymentMethod: webParams.paymentMethodText,
      rateQuestion: webParams.rateQuestionText,
      addCommentTitle: webParams.addCommentTitleText,
      orderHelpTitle: webParams.orderHelpTitleText,
      orderTotal: webParams.orderTotalText,
      orderPayment: webParams.orderPaymentText,
      discounts: webParams.discountsText,
      shipping: webParams.shippingText,
      qr: webParams.qrText,
      qrHelp: webParams.qrHelpText,
      orderLateText: webParams.orderLateText,
      commentHelpText: webParams.commentHelpText,
      productsRemoved: webParams.productsRemovedText,
      deliveryMainText: webParams.deliveryMainText
    },
    pricesSeparetedByComma: isTrue(webParams.pricesSeparetedByComma),
    currencyCode: webParams.currencyCode,
    payments: {
      cash: {
        text: webParams.paymentCashText,
        icon: webParams.paymentCashIcon
      },
      terminal: {
        text: webParams.paymentTerminalText,
        icon: webParams.paymentTerminalIcon
      },
      prepaid: {
        text: webParams.paymentPrepaidText,
        icon: webParams.paymentPrepaidIcon
      },
      loyaltyCard: {
        text: webParams.paymentLoyaltyCardText,
        icon: webParams.paymentLoyaltyCardIcon
      },
      link: {
        text: webParams.paymentLinkText,
        icon: webParams.paymentLinkIcon
      },
      transfer: {
        text: webParams.paymentTransferText,
        icon: webParams.paymentTransferIcon
      }
    },
    supportChat: {
      enable: isTrue(webParams.isSupportChat),
      chatMemberId: webParams.supportChatMemberId,
      tabSelected: webParams.supportChatTabSelected,
      chatMemberName: webParams.supportChatMemberName
    },
    stepView: {
      title: webParams.stepViewTitle
    },
    backgroundColor: webParams.backgroundColor,
    isFullScreen: isTrue(webParams.isFullScreen),
    isContactless: isTrue(webParams.isContactless),
    chatSupportTabHelpHtml: webParams.chatSupportTabHelpHtml,
    btnVariationSeeDetail: webParams.btnVariationSeeDetail,
    driverIcon: webParams.driverIcon,
    hasInvoice: isTrue(webParams.hasInvoice),
    showAddress: isTrue(webParams.showAddress),
    storeIcon: webParams.storeIcon,
    showPickerPhone: webParams.showPickerPhone,
    promoImageUrl: webParams.promoImageUrl,
    banner: {
      mobileImg: webParams.bannerMobileImg,
      desktopImg: webParams.bannerDesktopImg
    },
    goingToDestinationOnTransfer: webParams.goingToDestinationOnTransfer,
    showCancelInOrderLate: webParams.showCancelInOrderLate,
    homeBar: {
      iconSize: webParams.homeBarIconSize,
      fontSizes: {
        title: webParams.homeBarFontSizeTitle,
        subtitle: webParams.homeBarFontSizeSubtitle,
        steps: webParams.homeBarFontSizeSteps
      }
    },
    curbside: {
      fields: webParams.curbsideField
        ? getCurbsideConfiguration(webParams.curbsideField)
        : [],
      openModule: isTrue(webParams.curbsideOpenModule)
    },
    ...secretParams
  }
  return params
}

/**
 * Returns the app's params to be use in library mode
 *
 * @param {LibParams} libParams -The params object contains the library parameters to be set
 * @returns {Params} - The params object for library mode
 *
 * @function
 */
const adaptFromLibParams = (libParams: LibParams): Params => {
  const clientId = getClientId(libParams.token)
  const secretParams = clientId ? getParamsFromSecret(clientId) : {}

  const params: Params = {
    ...libParams,
    substeps: {
      received: {
        title:
          libParams.customStepsName?.received ||
          libParams.substeps?.received.title,
        icon: libParams.substeps?.received.icon
      },
      picking: {
        title:
          libParams.customStepsName?.picking ||
          libParams.substeps?.picking.title,
        icon: libParams.substeps?.picking.icon,
        iconInactive: libParams.substeps?.picking.iconInactive,
        iconActive: libParams.substeps?.picking.iconActive
      },
      goingToDestination: {
        title:
          libParams.customStepsName?.goingToDestination ||
          libParams.substeps?.goingToDestination.title,
        icon: libParams.substeps?.goingToDestination.icon,
        iconActive: libParams.substeps?.goingToDestination.iconActive,
        iconInactive: libParams.substeps?.goingToDestination.iconInactive
      },
      delivered: {
        title:
          libParams.customStepsName?.delivered ||
          libParams.substeps?.delivered.title,
        icon: libParams.substeps?.delivered.icon,
        iconActive: libParams.substeps?.delivered.iconActive,
        iconInactive: libParams.substeps?.delivered.iconInactive
      },
      readyForPickup: {
        title:
          libParams.customStepsName?.readyForPickup ||
          libParams.substeps?.readyForPickup.title,
        icon: libParams.substeps?.readyForPickup.icon,
        iconActive: libParams.substeps?.readyForPickup.iconActive,
        iconInactive: libParams.substeps?.readyForPickup.iconInactive
      },
      pickedUp: {
        title:
          libParams.customStepsName?.pickedUp ||
          libParams.substeps?.pickedUp.title,
        icon: libParams.substeps?.pickedUp.icon,
        iconActive: libParams.substeps?.pickedUp.iconActive,
        iconInactive: libParams.substeps?.pickedUp.iconInactive
      }
    },
    hideElements: {
      ...libParams.hideElements,
      orderHeader: isTrue(libParams.hideElements?.header),
      orderDelivery: isTrue(libParams.hideElements?.originDestination),
      deliveryCode: isTrue(libParams.hideElements?.deliveryCode),
      productQuantitiesChange: isTrue(
        libParams.hideElements?.productQuantitiesChange
      ),
      chatWithDriver: isTrue(libParams.hideElements?.chatWithDriver),
      productDelete: isTrue(libParams.hideElements?.productDelete),
      rateOrder:
        libParams.hideElements?.rateOrder === undefined
          ? true
          : isTrue(libParams.hideElements?.rateOrder),
      paymentStatus: isTrue(libParams.hideElements?.paymentStatus),
      orderMap: isTrue(libParams.hideElements?.orderMap),
      helpCenter: isTrue(libParams.hideElements?.helpCenter),
      decimals: isTrue(libParams.hideElements?.decimals),
      taxes: isTrue(libParams.hideElements?.taxes),
      orderHelp: isTrue(libParams.hideElements?.orderHelp),
      orderTransactions: isTrue(libParams.hideElements?.orderTransactions),
      cancelOrder: isTrue(libParams.hideElements?.cancelOrder),
      changeDeliveryDate:
        libParams.hideElements?.changeDeliveryDate === undefined
          ? true
          : isTrue(libParams.hideElements?.changeDeliveryDate),
      products: isTrue(libParams.hideElements?.products),
      orderId: isTrue(libParams.hideElements?.orderId),
      shipping: isTrue(libParams.hideElements?.shipping),
      discounts: isTrue(libParams.hideElements?.discounts),
      paymentMethod: isTrue(libParams.hideElements?.paymentMethod),
      orderDetail: isTrue(libParams.hideElements?.orderDetail),
      commentHelpText: isTrue(libParams.hideElements?.commentHelpText),
      productsAdd: isTrue(libParams.hideElements?.productsAdd),
      orderLate:
        libParams.hideElements?.orderLate === undefined
          ? true
          : isTrue(libParams.hideElements?.orderLate),
      chatWithPicker: isTrue(libParams.hideElements?.chatWithPicker),
      comments: isTrue(libParams.hideElements?.comments),
      chatWithSupport: isTrue(libParams.hideElements?.chatWithSupport),
      curbsideParkingPlace: isTrue(
        libParams.hideElements?.curbsideParkingPlace
      ),
      changeProductsReplacementSuggestions: isTrue(
        libParams.hideElements?.changeProductsReplacementSuggestions
      )
    },
    chat: {
      hideTab: getChatTabHide(libParams.chatChannels),
      defaultTab:
        getChatDefaultTab(libParams.defaultView?.chat) || ChatTab.SUPPORT,
      supportTab: {
        title: libParams.chat?.supportTab?.title,
        helpStartTime: libParams.chat?.supportTab?.helpStartTime,
        helpEndTime: libParams.chat?.supportTab?.helpEndTime
      },
      pickingTab: {
        title: libParams.chat?.pickingTab?.title
      },
      driverTab: {
        title: libParams.chat?.driverTab?.title
      }
    },
    steps: {
      confirmed: {
        title: libParams.steps?.confirmed?.title,
        help: libParams.steps?.confirmed?.help,
        icon: libParams.steps?.confirmed?.icon
      },
      picking: {
        title: libParams.steps?.picking?.title,
        help: libParams.steps?.picking?.help,
        icon: libParams.steps?.picking?.icon,
        iconActive: libParams.steps?.picking?.iconActive,
        iconInactive: libParams.steps?.picking?.iconInactive
      },
      goingToDestination: {
        title: libParams.steps?.goingToDestination?.title,
        help: libParams.steps?.goingToDestination?.help,
        icon: libParams.steps?.goingToDestination?.icon,
        iconActive: libParams.steps?.goingToDestination?.iconActive,
        iconInactive: libParams.steps?.goingToDestination?.iconInactive
      },
      readyToPickup: {
        title: libParams.steps?.readyToPickup?.title,
        help: libParams.steps?.readyToPickup?.help,
        icon: libParams.steps?.readyToPickup?.icon,
        iconActive: libParams.steps?.readyToPickup?.iconActive,
        iconInactive: libParams.steps?.readyToPickup?.iconInactive,
        images: libParams.steps?.readyToPickup?.images
      },
      pickedUp: {
        title: libParams.steps?.pickedUp?.title,
        help: libParams.steps?.pickedUp?.help,
        icon: libParams.steps?.pickedUp?.icon,
        iconActive: libParams.steps?.pickedUp?.iconActive,
        iconInactive: libParams.steps?.pickedUp?.iconInactive
      },
      delivered: {
        title: libParams.steps?.delivered?.title,
        help: libParams.steps?.delivered?.help,
        icon: libParams.steps?.delivered?.icon,
        iconActive: libParams.steps?.delivered?.iconActive,
        iconInactive: libParams.steps?.delivered?.iconInactive
      },
      cancel: {
        title: libParams.steps?.cancel?.title,
        help: libParams.steps?.cancel?.help,
        icon: libParams.steps?.cancel?.icon
      }
    },
    orderHelpHtml: libParams.orderHelpHtml,
    customTexts: {
      orderStatus: libParams.customTexts?.orderStatus,
      deliveryCode: libParams.customTexts?.deliveryCode,
      deliveryDate: libParams.customTexts?.deliveryDate,
      deliveryAddress: libParams.customTexts?.deliveryAddress,
      products: libParams.customTexts?.products,
      helpCenter: libParams.customTexts?.helpCenter,
      orderDetail: libParams.customTexts?.orderDetail,
      paymentMethod: libParams.customTexts?.paymentMethod,
      rateQuestion: libParams.customTexts?.rateQuestion,
      addCommentTitle: libParams.customTexts?.addCommentTitle,
      orderHelpTitle: libParams.customTexts?.orderHelpTitle,
      orderTotal: libParams.customTexts?.orderTotal,
      orderPayment: libParams.customTexts?.orderPayment,
      discounts: libParams.customTexts?.discounts,
      shipping: libParams.customTexts?.shipping,
      qr: libParams.customTexts?.qr,
      qrHelp: libParams.customTexts?.qrHelp,
      orderLateText: libParams.customTexts?.orderLateText,
      commentHelpText: libParams.customTexts?.commentHelpText,
      productsRemoved: libParams.customTexts?.productsRemoved,
      deliveryMainText: libParams.customTexts?.deliveryMainText
    },
    pricesSeparetedByComma: libParams.pricesSeparetedByComma,
    currencyCode: libParams.currencyCode,
    payments: {
      cash: {
        text: libParams.payments?.cash?.text,
        icon: libParams.payments?.cash?.icon
      },
      terminal: {
        text: libParams.payments?.terminal?.text,
        icon: libParams.payments?.terminal?.icon
      },
      prepaid: {
        text: libParams.payments?.prepaid?.text,
        icon: libParams.payments?.prepaid?.icon
      },
      loyaltyCard: {
        text: libParams.payments?.loyaltyCard?.text,
        icon: libParams.payments?.loyaltyCard?.icon
      },
      link: {
        text: libParams.payments?.link?.text,
        icon: libParams.payments?.link?.icon
      },
      transfer: {
        text: libParams.payments?.transfer?.text,
        icon: libParams.payments?.transfer?.icon
      }
    },
    supportChat: {
      enable: libParams.supportChat?.enable,
      chatMemberId: libParams.supportChat?.chatMemberId,
      tabSelected: libParams.supportChat?.tabSelected,
      chatMemberName: libParams.supportChat?.chatMemberName
    },
    stepView: {
      title: libParams.stepView?.title
    },
    backgroundColor: libParams.backgroundColor,
    isFullScreen: libParams.fullScreen,
    isContactless: libParams.isContactless,
    chatSupportTabHelpHtml: libParams.chatSupportTabHelpHtml,
    btnVariationSeeDetail: libParams.btnVariationSeeDetail,
    driverIcon: libParams.driverIcon,
    hasInvoice: libParams.hasInvoice,
    showAddress: libParams.showAddress,
    storeIcon: libParams.storeIcon,
    showPickerPhone: libParams.showPickerPhone,
    promoImageUrl: libParams.promoImageUrl,
    banner: {
      mobileImg: libParams.banner?.mobileImg,
      desktopImg: libParams.banner?.desktopImg
    },
    goingToDestinationOnTransfer: libParams.goingToDestinationOnTransfer,
    showCancelInOrderLate: libParams.showCancelInOrderLate,
    homeBar: {
      iconSize: libParams.homeBar?.iconSize,
      fontSizes: {
        title: libParams.homeBar?.fontSizes?.title,
        subtitle: libParams.homeBar?.fontSizes?.subtitle,
        steps: libParams.homeBar?.fontSizes?.steps
      }
    },
    curbside: {
      ...libParams.curbside,
      fields: libParams.curbside?.fields ?? [],
      openModule: libParams.curbside?.openModule
    },
    ...secretParams
  }
  return params
}

const getChatTabHide = function (chatTab: string): string {
  switch (chatTab) {
    case 'shoppers':
      return ChatTab.SUPPORT
    case 'support':
      return ChatTab.PICKING
    default:
      return ''
  }
}

const getChatDefaultTab = function (chatTab: string): string {
  switch (chatTab) {
    case 'shoppers':
      return ChatTab.PICKING
    case 'support':
      return ChatTab.SUPPORT
    default:
      return ChatTab.SUPPORT
  }
}

const getCurbsideConfiguration = (
  curbsideField: string | string[]
): { field: string; label: string; required: boolean }[] => {
  curbsideField = Array.isArray(curbsideField) ? curbsideField : [curbsideField]
  const fields = curbsideField.map((row) => {
    const field = row.split(',')
    return {
      field: field[0],
      required: isTrue(field[1]),
      label: field[2] ?? ''
    }
  })

  return fields
}

export { adaptFromWebParams, adaptFromLibParams }
