import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'
import type { ChatMember } from '../domain/ChatMember'
import type { ChatMessage } from '../domain/ChatMessage'
import { ChatTab } from '../domain/ChatTab'
import { ChatMemberType } from '../domain/ChatMemberType'
import { ChatGroup } from '../domain/ChatGroup'
import type { Chat } from '../domain/Chat'
import type { Params } from '@/shared/domain/Params'
import { ChatSupportTab } from '../domain/ChatSupportTab'
import { signedUrls } from '@/shared/services/signedUrls'

export const useChatStore = defineStore({
  id: 'chat',
  state: () => ({
    chats: <Chat[]>[
      {
        group: ChatGroup.PICKING,
        messages: [] as ChatMessage[]
      },
      {
        group: ChatGroup.SUPPORT,
        messages: [] as ChatMessage[]
      },
      {
        group: ChatGroup.RESOURCE,
        messages: [] as ChatMessage[]
      }
    ],
    selectedTab: ChatTab.SUPPORT,
    hasUnreadMessages: false,
    countMessagesCL_SUP: 0,
    countMessagesCL_SH: 0,
    countMessagesSH_SUP: 0,
    isTabSupportOpen: false,
    chatMembers: <ChatMember[]>[],
    isVisibleDrawer: false,
    chat: {
      hideTab: '',
      supportTab: '',
      pickingTab: '',
      driverTab: ''
    },
    supportChat: {
      enable: false,
      chatMemberId: '',
      tabSelected: ChatSupportTab.CUSTOMER,
      helpStartTime: '',
      helpEndTime: '',
      chatMemberName: ''
    },
    chatSupportTabHelpHtml: '',
    order: { id: '', reference: '', createdAt: { seconds: 0 } }
  }),
  actions: {
    updateChat: async function (
      chatGroup: ChatGroup,
      chatMessages: ChatMessage[]
    ) {
      const chat = this.chats.find((chat) => chat.group === chatGroup)

      for (let i = 0; i < chatMessages.length; i++) {
        if (chatMessages[i].urlOfAttachment) {
          // TODO: The signedUrls should be call only time
          const imageUrl = await signedUrls(
            localStorage.getItem('IL_token') as string
          )([chatMessages[i].urlOfAttachment])

          chatMessages[i].presignedUrlOfAttachment = imageUrl[0]
        }
      }

      if (chat.messages.length === 0) {
        chat.messages = chatMessages
      } else {
        chat.messages.push(chatMessages.pop())
      }
    },
    filterChatMembers(
      chatMembers: ChatMember[],
      type: ChatMemberType
    ): ChatMember {
      let chatMember
      if (type === ChatMemberType.SUPPORT) {
        chatMember = chatMembers.find(
          (chatMember) => chatMember.id === this.supportChat.chatMemberId
        )
      } else {
        chatMember = chatMembers.find((chatMember) => chatMember.type === type)
      }
      return chatMember || ({} as ChatMember)
    },
    updateChatMembers(chatMembers: ChatMember[]) {
      this.chatMembers = chatMembers
      let chatMember
      if (this.supportChat.enable) {
        chatMember = this.filterChatMembers(chatMembers, ChatMemberType.SUPPORT)
      } else {
        chatMember = this.filterChatMembers(chatMembers, ChatMemberType.USER)
      }
      this.updateHasUnreadMessages(chatMember)
    },
    updateHasUnreadMessages(chatMember: ChatMember) {
      const countShopperUnreadMsg =
        chatMember.summary?.find(
          (summary) => summary.chatGroup === ChatGroup.PICKING
        )?.countOfMessages ?? 0
      const countSupportUnreadMsg =
        chatMember.summary?.find(
          (summary) => summary.chatGroup === ChatGroup.SUPPORT
        )?.countOfMessages ?? 0
      const countResourceUnreadMsg =
        chatMember.summary?.find(
          (summary) => summary.chatGroup === ChatGroup.RESOURCE
        )?.countOfMessages ?? 0

      this.countMessagesCL_SH = countShopperUnreadMsg
      this.countMessagesCL_SUP = countSupportUnreadMsg
      this.countMessagesSH_SUP = countResourceUnreadMsg

      const hasShopperUnreadMsg =
        chatMember.summary?.find(
          (summary) => summary.chatGroup === ChatGroup.PICKING
        )?.countOfMessages ?? false
      const hasSupportUnreadMsg =
        chatMember.summary?.find(
          (summary) => summary.chatGroup === ChatGroup.SUPPORT
        )?.countOfMessages ?? false
      // is not unread while viewing the chat
      const hasUnreadMsg =
        (hasShopperUnreadMsg || hasSupportUnreadMsg) && !this.isVisibleDrawer
      this.hasUnreadMessages = hasUnreadMsg
    },
    toggleDrawer(status?: boolean) {
      this.isVisibleDrawer = status || !this.isVisibleDrawer
      if (status) {
        this.hasUnreadMessages = false
      }
    },
    setStateFromParams(params: Params) {
      if (params.chat.hideTab !== undefined)
        this.chat.hideTab = params.chat.hideTab
      if (params.chat.defaultTab) this.selectedTab = params.chat.defaultTab

      this.supportChat.enable = params.supportChat.enable ?? false
      this.supportChat.chatMemberId = params.supportChat.chatMemberId ?? null
      this.supportChat.chatMemberName = params.supportChat.chatMemberName ?? ''
      this.supportChat.tabSelected =
        params.supportChat.tabSelected ?? ChatSupportTab.CUSTOMER

      this.chat.supportTab =
        params.chat.supportTab.title ?? useI18n().t('support')
      this.chat.pickingTab =
        params.chat.pickingTab.title ?? useI18n().t('picker')
      this.chat.driverTab = params.chat.driverTab.title ?? useI18n().t('driver')

      if (params.chatSupportTabHelpHtml !== undefined)
        this.chatSupportTabHelpHtml = params.chatSupportTabHelpHtml
      this.supportChat.helpStartTime =
        params.chat.supportTab.helpStartTime ?? ''
      this.supportChat.helpEndTime = params.chat.supportTab.helpEndTime ?? ''
    }
  },
  getters: {
    hasShopper: (state) => {
      return (
        state.chatMembers.find(
          (chatMember: ChatMember) => chatMember.type === ChatMemberType.SHOPPER
        ) !== undefined
      )
    }
  }
})
