/**
 * Enum for `UserType` parameter.
 * @readonly
 * @enum {string}
 * @property {string} CLIENT - this value represents users of type client.
 */

export enum UserType {
  CLIENT = 'CLIENT'
}
