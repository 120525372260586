import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'
import { ActiveTabParams } from '../domain/ActiveTabParams'
import type { Params } from '../shared/domain/Params'
import { ShowOnly } from '../domain/ShowOnly'
import DriverIcon from '@/assets/icons/truck.png'
import StoreIcon from '@/assets/icons/store.png'

export const useAppConfigStore = defineStore({
  id: 'app-config',
  state: () => ({
    token: '',
    isMobile: window.innerWidth < 768,
    firebase: {
      idToken: ''
    },
    color: {
      primary: '#5ac727',
      secondary: '#1890ff'
    },
    fontFamily: '',
    hideElements: {
      orderDetails: false,
      orderStatus: false,
      orderDelivery: false,
      orderHeader: false,
      deliveryCode: false,
      productQuantitiesChange: false,
      chatWithDriver: false,
      productDelete: false,
      rateOrder: true,
      paymentStatus: false,
      orderMap: false,
      helpCenter: false,
      decimals: false,
      taxes: false,
      orderHelp: false,
      orderTransactions: false,
      cancelOrder: false,
      changeDeliveryDate: true,
      products: false,
      orderId: false,
      shipping: false,
      discounts: false,
      orderDetail: false,
      paymentMethod: false,
      commentHelpText: false,
      productsAdd: false,
      orderLate: false,
      chatWithPicker: false,
      comments: false,
      chatWithSupport: false,
      curbsideParkingPlace: false,
      changeProductsReplacementSuggestions: false
    },
    onlyElements: {
      chat: false
    },
    showOnly: '',
    rescheduleModal: {
      isVisible: false
    },
    ratingModal: {
      isVisible: false,
      currentScore: ''
    },
    orderHelpHtml: '',
    customTexts: {
      orderStatus: '',
      deliveryCode: '',
      deliveryDate: '',
      deliveryAddress: '',
      products: '',
      helpCenter: '',
      orderDetail: '',
      paymentMethod: '',
      rateQuestion: '',
      addCommentTitle: '',
      orderHelpTitle: '',
      orderTotal: '',
      orderPayment: '',
      discounts: '',
      shipping: '',
      qr: '',
      qrHelp: '',
      orderLateText: '',
      commentHelpText: '',
      productsRemovedText: '',
      deliveryMainText: ''
    },
    stepView: {
      title: ''
    },
    pricesSeparetedByComma: false,
    backgroundColor: '#fff',
    isFullScreen: false,
    isContactless: false,
    btnVariationSeeDetail: '',
    driverIcon: DriverIcon,
    hasInvoice: false,
    showAddress: false,
    storeIcon: StoreIcon,
    showPickerPhone: false,
    promoImageUrl: '',
    isAuditing: false,
    banner: {
      mobileImg: '',
      desktopImg: ''
    },
    goingToDestinationOnTransfer: false,
    showCancelInOrderLate: false,
    curbside: {
      fields: <{ field: string; label: string; required: boolean }[]>[],
      openModule: false
    },
    positionOfContent: 'right',
    positionClassName: ''
  }),
  actions: {
    setIsMobile() {
      this.isMobile = window.innerWidth < 768
    },
    setStateFromParams(params: Params) {
      this.token = params.token
      if (params.activeTabs === ActiveTabParams.CHAT_TAB)
        this.onlyElements.chat = true
      if (params.showOnly) this.showOnly = params.showOnly
      if (params.theme.primaryColor !== undefined)
        this.color.primary = params.theme.primaryColor
      if (params.theme.secondaryColor !== undefined)
        this.color.secondary = params.theme.secondaryColor
      if (params.theme.fontFamily !== undefined)
        this.fontFamily = params.theme.fontFamily
      if (params.orderHelpHtml !== undefined)
        this.orderHelpHtml = params.orderHelpHtml
      this.customTexts.orderStatus =
        params.customTexts.orderStatus ?? useI18n().t('order.status')
      this.customTexts.deliveryCode =
        params.customTexts.deliveryCode ?? useI18n().t('deliveryCode')
      this.customTexts.deliveryDate =
        params.customTexts.deliveryDate ?? useI18n().t('delivery.dateAndTime')
      this.customTexts.deliveryAddress =
        params.customTexts.deliveryAddress ?? useI18n().t('delivery.address')
      this.customTexts.orderDetail =
        params.customTexts.orderDetail ?? useI18n().t('order.detail')
      this.customTexts.paymentMethod =
        params.customTexts.paymentMethod ?? useI18n().t('paymentMethod.title')
      this.customTexts.products =
        params.customTexts.products ?? useI18n().t('products')
      this.customTexts.helpCenter =
        params.customTexts.helpCenter ?? useI18n().t('helpCenter')
      this.customTexts.rateQuestion =
        params.customTexts.rateQuestion ?? useI18n().t('howDidYourOrderGo')
      this.customTexts.addCommentTitle =
        params.customTexts.addCommentTitle ?? useI18n().t('addComment.title')
      this.customTexts.orderHelpTitle =
        params.customTexts.orderHelpTitle ??
        useI18n().t('order.understandCharges.title')
      this.customTexts.orderTotal =
        params.customTexts.orderTotal ?? useI18n().t('order.total')
      this.customTexts.orderPayment =
        params.customTexts.orderPayment ?? useI18n().t('paymentMethod.subtitle')
      this.stepView.title = params.stepView.title ?? null
      this.customTexts.discounts =
        params.customTexts.discounts ?? useI18n().t('order.discounts')
      this.customTexts.shipping =
        params.customTexts.shipping ?? useI18n().t('order.shippingFee')
      this.customTexts.qr =
        params.customTexts.qr ?? useI18n().t('pickupQR.goToDeliveredZone')
      this.customTexts.qrHelp =
        params.customTexts.qrHelp ??
        useI18n().t('pickupQR.modal.showToDispatcher')
      this.customTexts.orderLateText =
        params.customTexts.orderLateText ?? useI18n().t('orderLate')
      this.customTexts.commentHelpText =
        params.customTexts.commentHelpText ?? useI18n().t('addComment.subtitle')

      this.customTexts.productsRemovedText =
        params.customTexts.productsRemoved ?? useI18n().t('productsRemoved')
      this.customTexts.deliveryMainText =
        params.customTexts.deliveryMainText ??
        useI18n().t('modal.deliveryCode.message')

      this.hideElements.deliveryCode = params.hideElements.deliveryCode ?? false
      this.hideElements.productQuantitiesChange =
        params.hideElements.productQuantitiesChange ?? false
      this.hideElements.productDelete =
        params.hideElements.productDelete ?? false
      this.hideElements.rateOrder = params.hideElements.rateOrder ?? false
      this.hideElements.paymentStatus =
        params.hideElements.paymentStatus ?? false
      this.hideElements.orderMap = params.hideElements.orderMap ?? false
      this.hideElements.helpCenter = params.hideElements.helpCenter ?? false
      this.hideElements.decimals = params.hideElements.decimals ?? false
      this.hideElements.taxes = params.hideElements.taxes ?? false
      this.hideElements.orderHelp = params.hideElements.orderHelp ?? false
      this.hideElements.orderTransactions =
        params.hideElements.orderTransactions ?? false
      this.pricesSeparetedByComma = params.pricesSeparetedByComma ?? false
      this.isFullScreen = params.isFullScreen ?? false
      this.hideElements.changeDeliveryDate =
        params.hideElements.changeDeliveryDate ?? false
      this.hideElements.products = params.hideElements.products ?? false
      this.hideElements.orderId = params.hideElements.orderId ?? false
      this.hideElements.shipping = params.hideElements.shipping ?? false
      this.hideElements.discounts = params.hideElements.discounts ?? false
      this.hideElements.orderLate = params.hideElements.orderLate ?? false
      this.hideElements.orderDetails = !!(
        params.activeTabs === ActiveTabParams.STATE_TAB
      )
      this.hideElements.orderStatus = !!(
        params.activeTabs === ActiveTabParams.DETAIL_TAB
      )
      this.hideElements.orderDelivery =
        params.hideElements.orderDelivery ?? false
      this.hideElements.orderHeader = params.hideElements.orderHeader ?? false
      this.hideElements.cancelOrder = params.hideElements.cancelOrder ?? false
      this.hideElements.orderDetail = params.hideElements.orderDetail ?? false
      this.hideElements.paymentMethod =
        params.hideElements.paymentMethod ?? false
      this.hideElements.commentHelpText =
        params.hideElements.commentHelpText ?? false
      this.hideElements.productsAdd = params.hideElements.productsAdd ?? false
      this.hideElements.comments = params.hideElements.comments ?? false
      this.hideElements.chatWithSupport =
        params.hideElements.chatWithSupport ?? false

      this.hideElements.curbsideParkingPlace =
        params.hideElements.curbsideParkingPlace ?? false
      this.hideElements.changeProductsReplacementSuggestions =
        params.hideElements.changeProductsReplacementSuggestions ?? false

      this.backgroundColor =
        params.showOnly === ShowOnly.STEPS
          ? params.backgroundColor ?? '#f6f6f6'
          : params.backgroundColor ?? '#fff'
      this.isContactless = params.isContactless ?? false
      this.btnVariationSeeDetail = params.btnVariationSeeDetail

      this.driverIcon = params.driverIcon ?? DriverIcon
      this.storeIcon = params.storeIcon ?? StoreIcon

      this.hasInvoice = params.hasInvoice

      this.showPickerPhone =
        params.showPickerPhone === true || params.showPickerPhone === 'true'
      this.showAddress = params.showAddress ?? false

      this.promoImageUrl = params.promoImageUrl ?? ''
      this.banner.mobileImg = params.banner.mobileImg ?? ''
      this.banner.desktopImg = params.banner.desktopImg ?? ''

      this.goingToDestinationOnTransfer =
        params.goingToDestinationOnTransfer === true ||
        params.goingToDestinationOnTransfer === 'true'
      this.showCancelInOrderLate =
        params.showCancelInOrderLate === true ||
        params.showCancelInOrderLate === 'true'

      this.curbside.fields = params.curbside.fields
      this.curbside.openModule = params.curbside.openModule
    }
  }
})
