/**
 * Get the params from the URL and return them in an object
 *
 * @param url - The URL string
 * @returns {T} - The object with params and values as `paramName: value` pairs
 *
 * @function
 */
const extractParamsFromUrl = <T>(url?: string): T => {
  const searchParams = new URLSearchParams(url || document.location.search)

  const params: object = {}

  for (const [key, value] of searchParams.entries()) {
    params[key] = params[key]
      ? Array.isArray(params[key])
        ? [...params[key], value]
        : [params[key], value]
      : value
  }

  return params as T
}

/**
 * Get the path from the URL and return it in a string
 *
 * @param url - The URL string
 * @returns {string} - The string with the path only
 *
 * @function
 */
const getPathOfUrl = (url: string) => {
  const path = new URL(url).pathname.slice(1)
  return path
}
export { extractParamsFromUrl, getPathOfUrl }
