/**
 * Enum for tabs to the channel type in chat support. It is used to handle the chat tabs in chat component
 * @readonly
 * @enum {string}
 * @property {string} RESOURCE - It is the enum for Support to Shopper chat tab
 * @property {string} CUSTOMER - It is the enum for Support to User chat tab
 * @property {string} REVIEW - It is the enum for User to Shopper chat tab when support chat is enable
 */
export enum ChatSupportTab {
  RESOURCE = 'resource',
  CUSTOMER = 'customer',
  REVIEW = 'review'
}
