import { Loading } from '@/shared/types/Loading'
import type { StepState } from '../domain/StepState'
import { type Order, OrderStatus, StepTypes } from '../domain/Order'

export const isLoadOrderLoading = (step: StepState) => (): boolean => {
  return step.loading.loadOrder !== Loading.FULFILLED
}

export const getOrder = (step: StepState) => (): Order => {
  return step.immutable.order
}

export const isOrderCancelled = (step: StepState) => (): boolean => {
  return step.immutable.order.status === OrderStatus.CANCELLED
}

export const hasOrderStepDelivery = (step: StepState) => (): boolean => {
  return step.immutable.order.steps.four.type === StepTypes.DELIVERED
}

export const hasOrderStepReadyForPickup = (step: StepState) => (): boolean => {
  return (
    step.immutable.order.steps.three.type === StepTypes.READY_FOR_PICKUP ||
    step.immutable.order.steps.four.type === StepTypes.READY_FOR_PICKUP
  )
}
