/**
 * Get language of navigator
 * @returns {string} - The string with language of navigator
 * @function
 */
export const getLanguageOfNavigator = () => {
  const language =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language
  return language
}

const longFormat = {
  year: 'numeric',
  month: 'long',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric'
}

const monthFormat = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
}

const onlyDayShortFormat = {
  year: 'numeric',
  month: 'short',
  day: '2-digit'
}

const onlyDayLongFormat = {
  year: 'numeric',
  month: 'long',
  day: '2-digit'
}

const shortDayFormat = {
  weekday: 'short'
}

const shortMonthFormat = {
  month: 'short',
  day: '2-digit'
}

const onlyHourShortFormat = {
  hour: '2-digit',
  minute: 'numeric',
  hour12: true
}

const onlyHourLongFormat = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
}

/**
 * Set custom formats for Dates values used by language
 * @constant
 * @type {object}
 * @property {object} long - Set a format Date as `July 05, 2022 at 11:10 AM` when english (en) is selected
 * @property {object} onlyDay - Set a format Date as `06 Wednesday` when english (en) is selected
 * @property {object} onlyHour - Set a format Date as `11:10 AM` when english (en) is selected
 */
export const dateTimeFormats = {
  es: {
    long: longFormat,
    month: monthFormat,
    onlyDay: onlyDayShortFormat,
    shortDay: shortDayFormat,
    shortMonth: shortMonthFormat,
    onlyHour: onlyHourShortFormat
  },
  'es-419': {
    long: longFormat,
    month: monthFormat,
    onlyDay: onlyDayShortFormat,
    shortDay: shortDayFormat,
    shortMonth: shortMonthFormat,
    onlyHour: onlyHourShortFormat
  },
  en: {
    long: longFormat,
    month: monthFormat,
    onlyDay: onlyDayLongFormat,
    shortDay: shortDayFormat,
    shortMonth: shortMonthFormat,
    onlyHour: onlyHourLongFormat
  },
  pt: {
    long: longFormat,
    month: monthFormat,
    onlyDay: onlyDayLongFormat,
    shortDay: shortDayFormat,
    shortMonth: shortMonthFormat,
    onlyHour: onlyHourLongFormat
  },
  'pt-BR': {
    long: longFormat,
    month: monthFormat,
    onlyDay: onlyDayLongFormat,
    shortDay: shortDayFormat,
    shortMonth: shortMonthFormat,
    onlyHour: onlyHourLongFormat
  },
  pl: {
    long: longFormat,
    month: monthFormat,
    onlyDay: onlyDayLongFormat,
    onlyHour: onlyHourLongFormat
  },
  it: {
    long: longFormat,
    month: monthFormat,
    onlyDay: onlyDayLongFormat,
    onlyHour: onlyHourLongFormat
  }
}
